<div class="content">
  <div class="background">
    <!--  Background-->
  </div>
  <div class="title">
    <h1>Container-</h1>
    <h1>portal</h1>
  </div>
  <div class="login">
    <mat-card class="login-card">
      <mat-card-title class="login-logo">
        <img src="/assets/logo-bohnenkamp.svg" alt="Bohnenkamp | Moving Professionals">
      </mat-card-title>
      <mat-card-content>
        <form [formGroup]="loginForm" class="login-form" #form="ngForm" autocomplete="off">
          <div class="login-inputs">
            <mat-form-field appearance="none">
              <mat-label>Benutzername</mat-label>
              <input class="dark-input" formControlName="username" type="text" matInput>
              <mat-error *ngIf="hasError('username','required')">Bitte geben Sie Ihren Benutzernamen ein.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="none">
              <mat-label>Passwort</mat-label>
              <input class="dark-input" formControlName="password" [type]="hidePassword ? 'password' : 'text'" matInput>
              <button mat-icon-button matSuffix class='show-password-button' type='button'
                      (click)='hidePassword = !hidePassword'>
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="hasError('password','required')">Bitte geben Sie Ihr Passwort ein.</mat-error>
              <mat-error *ngIf="hasError('password','invalidCredentials')">Das eingegebene Passwort ist falsch.</mat-error>
            </mat-form-field>
          </div>
          <div class="login-button">
            <button mat-flat-button color="primary" [class.spinner]="loginPending" (click)="onLogin()" [disabled]="loginPending">Anmelden</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
