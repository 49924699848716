import { Pipe, PipeTransform } from '@angular/core';
import { LocalDate, LocalDateTime } from '@js-joda/core';
import { DatePipe } from '@angular/common';

/**
 * Date-Pipe for JS-Joda-Objects.
 *
 * !This currently uses the angular-date-pipe to keep the existing patterns specified throughout the app!
 */
@Pipe({
  name: 'jodaDate',
  pure: true,
})
export class JodaDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: LocalDate | LocalDateTime | null | undefined, pattern?: string): string {
    // const pattern = !!pattern
    //   ? DateTimeFormatter.ofPattern(pattern)
    //   : DateTimeFormatter.ISO_LOCAL_DATE;
    // pattern.pattern(value)
    return !!value ? this.datePipe.transform(value.toString(), pattern) : null;
  }
}
