import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { NullValidationHandler, OAuthService, OAuthSuccessEvent, TokenResponse } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { debounceTime, filter } from 'rxjs/operators';
import { User } from './user';
import { NotificationService } from '../../shared/notification/notification.service';
import jwt_decode from 'jwt-decode';
import { AllowedRoles } from './allowed-roles';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly connection: Promise<OAuthSuccessEvent>;

  constructor(private oAuthService: OAuthService, private notificationService: NotificationService, private logger: NGXLogger) {
    this.connection = this.configureOAuthService();
  }

  /**
   * Obtains an access token with the given credentials.
   * @param username -
   * @param password -
   */
  public login(username: string, password: string): Promise<TokenResponse> {
    return this.connection.then(() => {
      this.logout();

      return this.oAuthService.fetchTokenUsingPasswordFlow(username, password).then(resp => {
        return resp;
      });
    });
  }

  /**
   * Retrieves current user profile information.
   */
  public getUserInfo(): Promise<User> {
    return this.connection.then(() => {
      return this.oAuthService.loadUserProfile().then((data: { info: User }) => data.info);
    });
  }

  /**
   * Checks if a valid access token is present and has any valid role.
   */
  public isAuthenticated(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  /**
   * Logs the current user out and deletes the access token.
   */
  public logout(): void {
    this.oAuthService.logOut(true);
  }

  /**
   * Checks if the current user has the role 'admin' in the 'contplanner' client
   */
  public isAdmin(): boolean {
    return this.hasRoleInClient(AllowedRoles.ADMIN, environment.auth.clientId);
  }

  /**
   * Checks if the current user has any valid role in the 'contplanner' client
   */
  public hasAccess(): boolean {
    return this.isAdmin() || this.hasRoleInClient(AllowedRoles.USER, environment.auth.clientId);
  }

  private hasRoleInClient(role: string, client: string): boolean {
    const token = this.getDecodedAccessToken();
    const clientResourceAccess = token.resource_access[client];

    if (clientResourceAccess != null) {
      const roles = clientResourceAccess.roles as string[];
      return roles.includes(role);
    } else {
      return false;
    }
  }

  private configureOAuthService(): Promise<OAuthSuccessEvent> {
    this.oAuthService.configure(environment.auth);
    this.oAuthService.oidc = false;
    this.oAuthService.disableAtHashCheck = true;
    this.oAuthService.tokenValidationHandler = new NullValidationHandler();

    this.setAutomaticTokenRefresh();

    return this.oAuthService
      .loadDiscoveryDocument()
      .then(res => {
        this.logger.debug('Discovery document from Keycloak server retrieved successfully.');
        return res;
      })
      .catch(() => {
        this.notificationService.error(
          'Verbindung zum Authentifizierungsserver fehlgeschlagen. ' + 'Anmeldung nicht möglich. Bitte versuchen Sie es später noch einmal.'
        );
        return null;
      });
  }

  private setAutomaticTokenRefresh(): void {
    this.oAuthService.events
      .pipe(
        filter(e => e.type === 'token_expires'),
        debounceTime(1000)
      )
      .subscribe(() => {
        this.oAuthService
          .refreshToken()
          .then(res => {
            this.logger.debug('Token refreshed successfully. Token response: ' + res);
          })
          .catch(() => {
            console.log('Refreshing token failed.');
            this.logout();
          });
      });
  }

  private getDecodedAccessToken(): any {
    try {
      return jwt_decode(this.oAuthService.getAccessToken());
    } catch (Error) {
      return null;
    }
  }
}
