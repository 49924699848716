import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

export enum NotificationType {
  SUCCESS,
  INFO,
  ERROR,
  WARN,
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackbar: MatSnackBar) { }

  /**
   * Shows info snackbar with the given message.
   * @param message -
   * @param duration -
   */
  public info(message: string, duration: number = null): void {
    this.create(message, NotificationType.INFO, duration);
  }

  /**
   * Shows success snackbar with the given message.
   * @param message -
   * @param duration -
   */
  public success(message: string, duration: number = null): void {
    this.create(message, NotificationType.SUCCESS, duration);
  }

  /**
   * Shows error snackbar with the given message.
   * @param message -
   * @param duration -
   */
  public error(message: string, duration: number = null): void {
    this.create(message, NotificationType.ERROR, duration);
  }

  /**
   * Shows warn snackbar with the given message.
   * @param message -
   * @param duration -
   */
  public warn(message: string, duration: number = null): void {
    this.create(message, NotificationType.WARN, duration);
  }

  private create(message: string, type: NotificationType, duration: number = null): void {
    this.snackbar.open(message, 'Ok', this.createOptions(type, duration));
  }

  private createOptions(type: NotificationType, duration: number = null): MatSnackBarConfig {
    const [style, defaultDuration] = this.getLayoutData(type);
    duration = duration ?? defaultDuration;

    return {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration,
      panelClass: style,
    };
  }

  private getLayoutData(type: NotificationType): [string, number] {
    switch (type) {
      case NotificationType.SUCCESS:
        return ['success', 5000];
      case NotificationType.INFO:
        return ['info', 5000];
      case NotificationType.ERROR:
        return ['error', 6000];
      case NotificationType.WARN:
        return ['warn', 6000];
    }
  }

}
