import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  links = [
    { path: 'analysis', label: 'Analyse', icon: 'insights' },
    { path: 'simulation', label: 'Simulation starten', icon: 'offline_bolt' },
    { path: 'settings', label: 'Einstellungen', icon: 'settings' },
    { path: 'profile', label: 'Profil', icon: 'account_circle' },
  ];

  activeLink = 'simulation';
  background: ThemePalette = undefined;
}
