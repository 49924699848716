import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {environmentLoader} from './environments/environment.loader';

environmentLoader.then(update => {
  Object.assign(environment, update);

  if (environment.production) {
    enableProdMode();
  }

  if (update.api != null) {
    environment['allowedUrls'] = update.api;
  } else {
    environment['allowedUrls'] = environment.api;
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
