import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalDate, LocalDateTime } from '@js-joda/core';

/**
 * Interceptor detects and converts ISO-date-strings in REST-Responses to the joda-time-variants.
 */
@Injectable()
export class DateInterceptor implements HttpInterceptor {
  private isoDateTimeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;
  private isoDateFormat = /^\d{4}-\d{2}-\d{2}$/;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((val: HttpEvent<any>) => {
        if (val instanceof HttpResponse) {
          const body = val.body;
          this.fromIso(body);
        }
        return val;
      })
    );
  }

  private fromIso(body: any): void {
    if (body === null || body === undefined) {
      return;
    } else if (body instanceof Array) {
      for (const child of body) {
        this.fromIso(child);
      }
    } else if (typeof body === 'object') {
      for (const key of Object.keys(body)) {
        const value = body[key];
        if (this.isIsoDateString(value)) {
          body[key] = LocalDate.parse(value);
        } else if (this.isIsoDateTimeString(value)) {
          const cleaned = (value as string).replace('Z', '');
          body[key] = LocalDateTime.parse(cleaned);
        } else if (typeof value === 'object' || value instanceof Array) {
          this.fromIso(value);
        }
      }
    }
  }

  private isIsoDateString(value: any): boolean {
    return !!value && typeof value === 'string' && this.isoDateFormat.test(value);
  }

  private isIsoDateTimeString(value: any): boolean {
    return !!value && typeof value === 'string' && this.isoDateTimeFormat.test(value);
  }
}
