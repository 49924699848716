import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/notification/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private expiredLoginDisplayed = false;

  constructor(private router: Router, private notifications: NotificationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
    // .pipe(
    //   map((event: HttpEvent<unknown>) => event),
    //   catchError((error: HttpErrorResponse) => {
    //     switch (error.status) {
    //       case 401:
    //         this.handleUnauthorizedRequest();
    //         return throwError(error);
    //       default:
    //         return throwError(error);
    //     }
    //   })
    // );
  }

  private handleUnauthorizedRequest(): void {
    if (!this.expiredLoginDisplayed) {
      this.navigateToLogin();
      this.expiredLoginDisplayed = true;
    }
  }

  private navigateToLogin(): void {
    this.notifications.info('Deine Anmeldung ist abgelaufen, bitte logge dich erneut ein.');
    this.router.navigateByUrl('/authenticate?force');
  }
}
