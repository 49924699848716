<div class="main-content">
  <mat-toolbar class="main-toolbar">
    <div class="main-brand">
      <img src="assets/logo-bohnenkamp-minimal.svg" alt="Bohnenkamp" />
      <h1 class="main-brand-title">Containerportal</h1>
    </div>

    <span class="main-toolbar-spacer"></span>

    <nav mat-tab-nav-bar [backgroundColor]="background">
      <a
        mat-tab-link
        *ngFor="let link of links"
        [ngSwitch]="link.path"
        (click)="activeLink = link.path"
        [active]="activeLink === link.path"
        [routerLink]="link.path"
        routerLinkActive="active-link"
      >
        <mat-icon>{{ link.icon }}</mat-icon>
        <span class="link-label">{{ link.label }}</span>
      </a>
    </nav>
  </mat-toolbar>

  <router-outlet></router-outlet>
</div>
