import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

/**
 * Checks if the user is logged in. If not, redirects to the login page.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private logger: NGXLogger
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isAuthenticated()) {
      this.logger.warn('Session not authenticated, redirecting to login.');
      window.location.replace('/login');
      return false;
    } else if (!this.authService.hasAccess()) {
      this.authService.logout();
      this.logger.warn(
        'Current user does not have sufficient rights for this application.'
      );
      window.location.replace('/login');
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
