import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { LoggerModule } from 'ngx-logger';
import { environment } from '../environments/environment';
import { OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { DateInterceptor } from './core/interceptors/date.interceptor';
import { RouterModule } from '@angular/router';
import { MainComponent } from './core/main/main.component';
import { MaterialModule } from './shared/material.module';
import { LoginComponent } from './core/login/login.component';
import { JodaDatePipe } from './shared/pipes/joda-date.pipe';
import { CropTextPipe } from './shared/pipes/crop-text.pipe';

registerLocaleData(localeDe);

/**
 * Storage for the OAuth service.
 */
export function storageFactory(): OAuthStorage {
  return localStorage;
}

/**
 * Custom factory for OAuth Config.
 * Used to set the correct allowedUrls that are loaded dynamically from environment.json via environment.loader
 */
export function oAuthConfigFactory(): OAuthModuleConfig {
  return {
    resourceServer: {
      allowedUrls: [environment['allowedUrls']],
      sendAccessToken: true,
    },
  };
}

@NgModule({
  declarations: [AppComponent, MainComponent, LoginComponent],
  imports: [
    // Global modules
    BrowserModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot(environment.logger),
    OAuthModule.forRoot(),
    RouterModule,
    HttpClientModule,

    // Core-Modules
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: OAuthModuleConfig, useFactory: oAuthConfigFactory },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    JodaDatePipe,
    CropTextPipe,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
