// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  // api: 'https://containervereinnahmung.dev.lmis.de/backend/api/public',
  service: 'http://localhost:8081/backend',
  api: 'http://localhost:8081/backend/api/public',
  logger: {
    serverLogLevel: NgxLoggerLevel.OFF,
    serverLoggingUrl: null,
    disableConsoleLogging: false,
    level: NgxLoggerLevel.DEBUG,
  },
  auth: {
    // issuer: 'https://contplanner.dev.lmis.de/auth/realms/Bohnenkamp',
    issuer: 'http://localhost:8080/realms/Bohnenkamp',
    clientId: 'contplanner',
    dummyClientSecret: '',
    scope: 'openid profile email',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
