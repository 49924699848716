import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginPending = false;
  hidePassword = true;

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(private authenticationService: AuthenticationService, private notificationService: NotificationService, private router: Router) {}

  ngOnInit(): void {
    this.redirectIfAlreadyAuthenticated();
  }

  private redirectIfAlreadyAuthenticated(): void {
    if (this.authenticationService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  /**
   * Checks if the input is valid and performs a login via authentication service.
   */
  public onLogin(): void {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.valid) {
      this.loginPending = true;
      this.authenticationService
        .login(this.loginForm.get('username').value, this.loginForm.get('password').value)
        .then(() => this.router.navigate(['/']))
        .catch(err => {
          console.log(err);
          if (err.status === 401) {
            this.loginForm.get('password').setErrors({ invalidCredentials: true });
            this.notificationService.error('Login fehlgeschlagen. Falsche Anmeldedaten.');
          } else if (err.status === 403) {
            this.notificationService.error('Login fehlgeschlagen. Sie haben keine Berechtigungen für diese Applikation.');
          } else {
            this.notificationService.error('Anmeldung fehlgeschlagen. Fehlercode: ' + err.status);
          }
        })
        .finally(() => (this.loginPending = false));
    }
  }

  /**
   * Checks if the control of the given input has the given error.
   * @param controlName -
   * @param errorName -
   */
  public hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };
}
